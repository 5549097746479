import React, {useState, useEffect} from 'react'
import sessionManager from '../util/sessionManager'
import socketManager from '../util/socketManager'
import NavBar from '../components/NavBar'


export default function AboutPage() {
  const [global, setGlobal] = useState(sessionManager.getState())
  const dispatch = sessionManager.dispatch


  useEffect(function () {
    return sessionManager.subscribe(function () {
      setGlobal(sessionManager.getState())
    })
  }, [])

	return (
    <main>
      <title>About</title>

			<NavBar global={global} dispatch={dispatch} />

			<h1>
				About
			</h1>

			<p>
				......
			</p>

		</main>
	)
}
